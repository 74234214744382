import ActionTypes from "../constants";

const initialState = {
  adminDetails: {},
};

const Admin = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.STORE_ADMIN_DETAIL:
      console.log("admin details in redux", action.payload);
      return {
        ...state,
        adminDetails: action.payload,
      };

    default:
      return state;
  }
};

export default Admin;
