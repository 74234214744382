import React, { Component } from "react";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";

import Header from "../Header/index";

class Amenities extends Component {
  constructor(props) {
    super(props);
    const retrievedObject = getLocalStorageItem("onboardingDetails");
    this.state = {
      userId: retrievedObject ? retrievedObject.userId : "",
      token: retrievedObject ? retrievedObject.token : "",
      successMsg: "",
      errorMsg: "",
      redirect: false,
      loading: false,
      amenities: {
        music: "No",
        parking_space: "No",
        credit_cards_accepted: "No",
        wi_fi: "No",
        pets_friendly: "No",
        child_friendly: "No",
        selfie_station: "No",
      },
      aawl: {
        toilet: "No",
        hygiene: "No",
        drinking_water: "No",
        ac: "No",
      },
    };
  }

  componentDidMount() {
    this.fetchUserData();
  }

  fetchUserData = async () => {
    this.setState({ loading: true });
    const { token, userId } = this.state;

    const { AdminExpertUserDetails } = adminendPoints;
    const method = "POST";
    const contentType = "application/json";

    try {
      const body = { token, userid: userId };
      const response = await APICaller(
        AdminExpertUserDetails,
        method,
        body,
        contentType,
        token
      );

      const { status, user } = response.data;

      if (status === 200 && user) {
        this.setState({
          loading: false,
          amenities: {
            music: user.amenities?.[0]?.music || "No",
            parking_space: user.amenities?.[0]?.parking_space || "No",
            credit_cards_accepted:
              user.amenities?.[0]?.credit_cards_accepted || "No",
            wi_fi: user.amenities?.[0]?.wi_fi || "No",
            pets_friendly: user.amenities?.[0]?.pets_friendly || "No",
            child_friendly: user.amenities?.[0]?.child_friendly || "No",
            selfie_station: user.amenities?.[0]?.selfie_station || "No",
          },
          aawl: {
            toilet: user.aawl?.[0]?.toilet || "No",
            hygiene: user.aawl?.[0]?.hygiene || "No",
            drinking_water: user.aawl?.[0]?.drinking_water || "No",
            ac: user.aawl?.[0]?.ac || "No",
          },
        });
      } else {
        console.error("Failed to fetch user data");
        this.setState({
          errorMsg: "Failed to fetch user data",
          loading: false,
        });
      }
    } catch (error) {
      console.error("Failed to fetch user data:", error);
      this.setState({ errorMsg: "Failed to fetch user data", loading: false });
    }
  };

  validateForm = () => {
    const { amenities, aawl } = this.state;
    const newErrors = {};

    if (!Object.values(amenities).some((value) => value === "Yes")) {
      newErrors.amenities = "At least one amenity must be selected.";
    }

    if (!Object.values(aawl).some((value) => value === "Yes")) {
      newErrors.aawl = "At least one AAWL option must be selected.";
    }

    return newErrors;
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    this.setState({ loading: true });

    const newErrors = this.validateForm();

    if (Object.keys(newErrors).length > 0) {
      this.setState({ errorMsg: "", successMsg: "", errors: newErrors }, () => {
        setTimeout(() => {
          this.setState({ errors: null });
        }, 5000);
      });
    } else {
      this.setState({ errors: null });
      const { addresses, amenities, aawl, userId, token } = this.state;

      const { OnboardingSaveAmenities } = adminendPoints;
      const method = "POST";
      const body = {
        id: userId,
        token,
        amenities,
        aawl,
      };
      const contentType = "application/json";

      try {
        const response = await APICaller(
          OnboardingSaveAmenities,
          method,
          body,
          contentType
        );
        const { status, data } = response;

        if (data && status === 200) {
          this.setState(
            {
              successMsg: "Amenities updated successfully.",
              errorMsg: "",
              loading: false,
            },
            () => {
              setTimeout(() => {
                this.setState({ successMsg: "", redirect: true });
              }, 5000);
            }
          );
        } else {
          this.setState(
            {
              errorMsg: "Error saving amenities.",
              successMsg: "",
              loading: false, // Set loading to false
            },
            () => {
              setTimeout(() => {
                this.setState({ errorMsg: "", redirect: false });
              }, 3000);
            }
          );
        }
      } catch (error) {
        console.log("Error making data call:", error);
        this.setState(
          {
            errorMsg: "Error making data call.",
            successMsg: "",
            loading: false, // Set loading to false
          },
          () => {
            setTimeout(() => {
              this.setState({ errorMsg: "" });
            }, 3000);
          }
        );
      }
    }
  };

  handleInputChange = (e) => {
    const { name, checked } = e.target;
    const value = checked ? "Yes" : "No";

    if (["toilet", "hygiene", "drinking_water", "ac"].includes(name)) {
      this.setState((prevState) => ({
        aawl: {
          ...prevState.aawl,
          [name]: value,
        },
      }));
    } else {
      this.setState((prevState) => ({
        amenities: {
          ...prevState.amenities,
          [name]: value,
        },
      }));
    }
  };

  handleSuccClose = (e) => {
    this.setState({
      successMsg: "",
    });
  };

  handleErrorClose = (e) => {
    this.setState({
      errorMsg: "",
    });
  };

  render() {
    const { redirect, successMsg, errorMsg, amenities, aawl, loading, token } =
      this.state;

    if (!token) {
      return <Navigate to="/" />;
    }

    if (redirect) {
      return <Navigate to="/Treatment" />;
    }

    return (
      <div>
        <Header />
        <div className="content-area">
          <section className="common-content">
            <form className="custom-form" onSubmit={this.handleSubmit}>
              <div className="container">
                <div className="form-area custom-width-750 customForm">
                  <div className="form-body">
                    <div className="section-title">
                      <h3>Amenities</h3>
                    </div>

                    <div className="row">
                      <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className="mb-3">
                          <div className="amnities">
                            {[
                              {
                                name: "music",
                                label: "Music",
                                img: "music-icon.png",
                              },
                              {
                                name: "parking_space",
                                label: "Parking Space",
                                img: "parking.png",
                              },
                              {
                                name: "credit_cards_accepted",
                                label: "Credit Cards Accepted",
                                img: "credit.png",
                              },
                              {
                                name: "wi_fi",
                                label: "Wi-Fi",
                                img: "wifi.png",
                              },
                              {
                                name: "pets_friendly",
                                label: "Pets Friendly",
                                img: "pets.png",
                              },
                              {
                                name: "child_friendly",
                                label: "Child Friendly",
                                img: "child.png",
                              },
                              {
                                name: "selfie_station",
                                label: "Selfie Station",
                                img: "selfie.png",
                              },
                            ].map((amenity) => (
                              <label
                                key={amenity.name}
                                className={`amnity ${
                                  amenities[amenity.name] === "Yes"
                                    ? "active-check"
                                    : ""
                                }`}
                              >
                                <input
                                  className="child-checkbox"
                                  type="checkbox"
                                  name={amenity.name}
                                  checked={amenities[amenity.name] === "Yes"}
                                  onChange={this.handleInputChange}
                                />
                                <span>
                                  {amenity.label}
                                  <img
                                    src={`${process.env.PUBLIC_URL}/assets/img/${amenity.img}`}
                                    alt={amenity.label}
                                  />
                                </span>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                              </label>
                            ))}

                            {[
                              {
                                name: "toilet",
                                label: "Toilet",
                                img: "music-icon.png",
                              },
                              {
                                name: "drinking_water",
                                label: "Drinking Water",
                                img: "music-icon.png",
                              },
                              {
                                name: "ac",
                                label: "AC",
                                img: "music-icon.png",
                              },
                            ].map((aawlField) => (
                              <label
                                key={aawlField.name}
                                className={`amnity ${
                                  aawl[aawlField.name] === "Yes"
                                    ? "active-check"
                                    : ""
                                }`}
                              >
                                <input
                                  className="child-checkbox"
                                  type="checkbox"
                                  name={aawlField.name}
                                  checked={aawl[aawlField.name] === "Yes"}
                                  onChange={this.handleInputChange}
                                />
                                <span>
                                  {aawlField.label}
                                  <img
                                    src={`${process.env.PUBLIC_URL}/assets/img/${aawlField.img}`}
                                    alt={aawlField.label}
                                  />
                                </span>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                              </label>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer-container">
                <div className="container">
                  <div className="next-prv-button">
                    <Link to="/Salonphoto" className="nextBtn">
                      Back
                    </Link>
                    <div className="foo-btns">
                      <button type="submit" className="nextBtn">
                        Save
                      </button>
                      <Link to="/Treatment" className="nextBtn">
                        Next
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </section>
        </div>
        {loading && <div className="loader"></div>}
        <div className="error-success">
          {successMsg && (
            <div className="sucessMsg">
              <div className="msgCon">
                <i className="fa fa-check" aria-hidden="true"></i> {successMsg}
              </div>
              <div className="msgClose" onClick={this.handleSuccClose}>
                <i className="fa fa-times" aria-hidden="true"></i>
              </div>
            </div>
          )}

          {errorMsg && (
            <div className="errorMsg">
              <div className="msgCon">
                <i className="fa fa-times" aria-hidden="true"></i> Error:{" "}
                {errorMsg}
              </div>
              <div className="msgClose" onClick={this.handleErrorClose}>
                <i className="fa fa-times" aria-hidden="true"></i>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default Amenities;
