import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "../components/Login";
import Logout from "../components/Logout";
import Otp from "../components/Otp";
import Location from "../components/Location";
import Profile from "../components/Profile";
import UploadPhoto from "../components/UploadPhoto";
import Amenities from "../components/Amenities";
import Services from "../components/Services";
import Salonphoto from "../components/Salonphoto";
import Treatment from "../components/Treatment";
import PrivacyPolicy from "../components/PrivacyPolicy";
import ThankYou from "../components/ThankYou";

function Navigator() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/VerifyOTP" element={<Otp />} />
        <Route path="/Location" element={<Location />} />
        <Route path="/Profile" element={<Profile />} />
        <Route path="/UploadPhoto" element={<UploadPhoto />} />
        <Route path="/Amenities" element={<Amenities />} />
        <Route path="/Services" element={<Services />} />
        <Route path="/Salonphoto" element={<Salonphoto />} />
        <Route path="/Treatment" element={<Treatment />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/ThankYou" element={<ThankYou />} />
        <Route path="/Logout" element={<Logout />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Navigator;
