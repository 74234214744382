import React, { Component } from "react";
import { Navigate, Link } from "react-router-dom";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { setLocalStorageItem } from "../../store/localStorage";
import Header from "../Header/index";

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      phone: "",
      successMsg: "",
      errorMsg: "",
      redirect: false,
      errors: null,
      loading: false,
    };
  }

  validateForm = () => {
    const { phone } = this.state;
    const newErrors = {};

    if (!phone) {
      newErrors.phone = "Please enter your mobile number.";
    } else if (!/^\d{10}$/.test(phone)) {
      newErrors.phone = "Invalid phone format. It should be a 10-digit number.";
    }

    return newErrors;
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = this.validateForm();

    if (Object.keys(newErrors).length > 0) {
      this.setState({ errorMsg: "", successMsg: "", errors: newErrors }, () => {
        setTimeout(() => {
          this.setState({ errors: null });
        }, 5000);
      });
    } else {
      this.setState({ errors: null });

      const { phone } = this.state;
      const { OnboardingLogin } = adminendPoints;
      const method = "POST";
      const body = {
        phone,
      };
      const contentType = "application/json";
      this.setState({ loading: true });
      try {
        const response = await APICaller(
          OnboardingLogin,
          method,
          body,
          contentType
        );

        const { status, data } = response.data;

        if (status === 200) {
          setLocalStorageItem("onboardingDetails", data);

          this.setState(
            {
              successMsg: "OTP sent successfully.",
              errorMsg: "",
              loading: false,
              redirect: true,
            },
            () => {
              setTimeout(() => {
                this.setState({ successMsg: "" });
              }, 2000);
            }
          );
        } else {
          this.setState(
            {
              errorMsg: "Failed to send OTP. Please try again.",
              successMsg: "",
              loading: false,
            },
            () => {
              setTimeout(() => {
                this.setState({ errorMsg: "" });
              }, 3000);
            }
          );
        }
      } catch (error) {
        console.log("Error making data call:", error);
        this.setState({
          errorMsg: "Error sending OTP. Please try again.",
          successMsg: "",
          loading: false,
        });
      }
    }
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSuccClose = (e) => {
    this.setState({
      successMsg: "",
    });
  };

  handleErrorClose = (e) => {
    this.setState({
      errorMsg: "",
    });
  };

  render() {
    const { phone, errors, redirect, successMsg, errorMsg, loading } =
      this.state;

    if (redirect) {
      return <Navigate to="/VerifyOTP" />;
    }

    return (
      <div>
        <Header />
        <div className="content-area">
          <section className="common-content">
            <div className="container">
              <div className="form-area custom-width login-form">
                <div className="form-header">
                  <h4 className="mb-3 alignCenter">Log in or Sign up</h4>
                </div>
                <div className="form-body">
                  <h3>Welcome to MyStylist</h3>

                  <form className="custom-form" onSubmit={this.handleSubmit}>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="mb-3">
                          <input
                            type="tel"
                            className="form-control"
                            id="phone"
                            name="phone"
                            placeholder="Mobile Number"
                            value={phone}
                            onChange={this.handleInputChange}
                          />
                          {errors && errors.phone && (
                            <div className="text-danger">{errors.phone}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <p>
                          We’ll call or text you to confirm your number.
                          Standard message and data rates apply.{" "}
                          <Link to="PrivacyPolicy" target="_blank">
                            Privacy Policy
                          </Link>
                        </p>
                      </div>
                      <div className="col-md-12">
                        <button type="submit" className="btn-green cusBtn">
                          Continue
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
        {loading && <div className="loader"></div>}
        <div className="error-success">
          {successMsg && (
            <div className="sucessMsg">
              <div className="msgCon">
                <i className="fa fa-check" aria-hidden="true"></i> {successMsg}
              </div>
              <div className="msgClose" onClick={this.handleSuccClose}>
                <i className="fa fa-times" aria-hidden="true"></i>
              </div>
            </div>
          )}

          {errorMsg && (
            <div className="errorMsg">
              <div className="msgCon">
                <i className="fa fa-times" aria-hidden="true"></i> Error:{" "}
                {errorMsg}
              </div>
              <div className="msgClose" onClick={this.handleErrorClose}>
                <i className="fa fa-times" aria-hidden="true"></i>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Login;
