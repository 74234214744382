import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import {
  setLocalStorageItem,
  getLocalStorageItem,
} from "../../store/localStorage";
import Header from "../Header/index";

class ThankYou extends Component {
  constructor(props) {
    super(props);
    const retrievedObject = getLocalStorageItem("onboardingDetails");

    this.state = {
      userId: retrievedObject ? retrievedObject.userId : "",
      token: retrievedObject ? retrievedObject.token : "",
    };
  }

  render() {
    const { token } = this.state;

    if (!token) {
      return <Navigate to="/" />;
    }

    return (
      <div>
        <Header />
        <section className="common-content">
          <div className="container">
            <div className="thank-you">
              <img src="assets/img/thanks.png" />
              <p>
                Lorem Ipsum is simply dummy text of the printg and typeset
                industry lorem Ipsum has been the industry
              </p>
              <button type="submit" className="cusBtn thankBtn">
                Go to Dashboard
              </button>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    adminDetails: state.admin.adminDetails,
    phone:
      state.admin.adminDetails && state.admin.adminDetails.data
        ? state.admin.adminDetails.data.phone
        : "",
  };
};

export default connect(mapStateToProps)(ThankYou);
