import React, { Component } from "react";
import { Link, Navigate } from "react-router-dom";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import Header from "../Header/index";

import { getLocalStorageItem } from "../../store/localStorage";

class Treatment extends Component {
  constructor(props) {
    super(props);

    const retrievedObject = getLocalStorageItem("onboardingDetails");
    this.state = {
      userId: retrievedObject ? retrievedObject.userId : "",
      token: retrievedObject ? retrievedObject.token : "",
      successMsg: "",
      errorMsg: "",
      redirect: false,
      errors: {},
      services: [],
      userAssignServices: [],
      selectedService: null,
      subServices: [],
      selectedServiceId: null,
      radioSelections: {},
      loadingServices: false,
      featured_image_url: "https://mystylist-media.s3.amazonaws.com",
      isImageModalOpen: false,
      modalImageUrls: [],
      currentImageIndex: 0,
      assignFileNames: [],
      time_taken: "",
    };
  }

  componentDidMount() {
    this.fetchUserData();
  }

  fetchUserData = async () => {
    this.setState({ loadingServices: true });
    const { token, userId } = this.state;
    const { onboardingExpertServiceSubServices } = adminendPoints;
    const method = "GET";
    const contentType = "application/json";
    const url = `${onboardingExpertServiceSubServices}/${userId}`;

    try {
      const response = await APICaller(url, method, "", contentType, token);
      const { success, services } = response.data;

      if (success) {
        this.setState(
          {
            userAssignServices: services,
            loadingServices: false,
          },
          () => {
            this.fetchServices();
          }
        );
      }
      return;
    } catch (error) {
      console.error("Error fetching sub-services:", error);
    }
  };

  fetchServices = async () => {
    this.setState({ loadingServices: true });
    const { token, userAssignServices } = this.state;
    const { AdminServicesListMobile } = adminendPoints;
    const method = "GET";
    const contentType = "application/json";
    const stateUrl = `${AdminServicesListMobile}`;

    try {
      const response = await APICaller(
        stateUrl,
        method,
        "",
        contentType,
        token
      );
      const { status, services } = response.data;

      if (status === 200 && services.length > 0) {
        this.setState(
          {
            services,
            selectedService: services[0],
            selectedServiceId: services[0]._id,
            loadingServices: false,
          },
          () => this.fetchSubServices(services[0]._id)
        );
      }
    } catch (error) {
      console.error("Error fetching services:", error);
      this.setState({
        errorMsg:
          "An error occurred while fetching services. Please try again.",
      });
    }
  };

  fetchSubServices = async (serviceid) => {
    this.setState({ loadingServices: true });
    const { token, userAssignServices, userId } = this.state;
    const { AdminAllSubService } = adminendPoints;
    const method = "GET";
    const contentType = "application/json";
    const url = `${AdminAllSubService}/${serviceid}`;

    try {
      const response = await APICaller(url, method, "", contentType, token);
      const { status, service } = response.data;

      if (status === 200) {
        const subServices = service.subServices;

        const updatedSubServices = subServices.map((subService) => {
          const assignedServiceInfo = userAssignServices.find(
            (assigned) =>
              assigned.service_id === serviceid &&
              assigned.sub_services.some(
                (sub) => sub.sub_service_id === subService._id
              )
          );

          const assignedSubService = assignedServiceInfo
            ? assignedServiceInfo.sub_services.find(
                (sub) => sub.sub_service_id === subService._id
              )
            : null;

          return {
            ...subService,
            selected: !!assignedSubService,
            best_service: assignedSubService
              ? assignedSubService.best_service || ""
              : "",
            price: assignedSubService ? assignedSubService.price || "" : "",
            productUsed: assignedSubService
              ? assignedSubService.product_used || ""
              : "",
            file: assignedSubService ? assignedSubService.file || null : null,
            time_taken: {
              hours: assignedSubService
                ? assignedSubService.time_taken?.hours || ""
                : "",
              minutes: assignedSubService
                ? assignedSubService.time_taken?.minutes || ""
                : "",
            },
          };
        });

        this.setState({
          loadingServices: false,
          subServices: updatedSubServices,
          radioSelections: {},
        });
      } else {
        this.setState({
          errorMsg: "Invalid Request",
        });
      }
    } catch (error) {
      console.error("Error fetching sub-services:", error);
    }
  };

  validateForm = (index) => {
    const { subServices, radioSelections } = this.state;
    const errors = {};

    if (radioSelections[index] === undefined) {
      errors[`selectedRadio${index}`] = "Please select a radio button";
    }
    if (!subServices[index].price) {
      errors[`price${index}`] = "Price is required";
    } else if (
      isNaN(subServices[index].price) ||
      parseFloat(subServices[index].price) <= 0
    ) {
      errors[`price${index}`] = "Price must be a valid positive number";
    }
    if (
      !subServices[index].time_taken ||
      !subServices[index].time_taken.hours
    ) {
      errors[`hours${index}`] = "Hours are required";
    }

    if (!subServices[index].productUsed) {
      errors[`productUsed${index}`] = "Product Used is required";
    }

    if (!subServices[index].best_service) {
      errors[`best_service${index}`] = "Please select best service";
    }

    this.setState({ errors });
    return Object.keys(errors).length === 0;
  };

  handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const subServices = [...this.state.subServices];

    if (
      subServices[index].time_taken &&
      typeof subServices[index].time_taken === "string"
    ) {
      subServices[index].time_taken = JSON.parse(subServices[index].time_taken);
    }

    if (name === "hours" || name === "minutes") {
      subServices[index].time_taken = {
        ...subServices[index].time_taken,
        [name]: value,
      };
    } else {
      subServices[index][name] = value;
    }

    this.setState({ subServices });
  };

  handleFileChange = (e, index) => {
    const files = e.target.files;
    if (files.length > 6) {
      alert("You can only upload a maximum of 6 files.");
      return;
    }
    const subServices = [...this.state.subServices];
    subServices[index].file = files;
    this.setState({ subServices });
  };

  handleServiceSelect = (serviceId) => {
    this.setState(
      {
        selectedServiceId: serviceId,
        selectedService: this.state.services.find(
          (service) => service._id === serviceId
        ),
        errors: {},
      },
      () => this.fetchSubServices(serviceId)
    );
  };

  handleRadioChange = (index) => {
    const radioSelections = {};
    radioSelections[index] = true;
    this.setState({ radioSelections });
  };

  handleSubmit = async (e, index) => {
    e.preventDefault();
    this.setState({ loadingServices: true });
    if (this.validateForm(index)) {
      const { selectedServiceId, subServices, userId } = this.state;

      const { onboardingAssignService } = adminendPoints;
      const formData = new FormData();
      formData.append("user_id", userId);
      formData.append("service_id", selectedServiceId);
      formData.append("sub_service_id", subServices[index]._id);
      formData.append("best_service", subServices[index].best_service);
      formData.append("price", subServices[index].price);
      formData.append("product_used", subServices[index].productUsed);
      formData.append(
        "time_taken",
        JSON.stringify(subServices[index].time_taken)
      );

      const files = subServices[index].file;

      if (files && files.length > 0) {
        Array.from(files).forEach((file) => {
          formData.append("fileNames", file);
        });
      }

      try {
        const response = await APICaller(
          onboardingAssignService,
          "POST",
          formData,
          {
            "Content-Type": "multipart/form-data",
          }
        );

        const { status, data } = response;

        if (data && status === 200) {
          this.setState(
            {
              successMsg: "User Profile updated successfully.",
              errorMsg: "",
              loadingServices: false,
            },
            () => {
              setTimeout(() => {
                this.setState({ successMsg: "", redirect: true });
              }, 5000);
            }
          );
        } else {
          this.setState(
            {
              errorMsg: "Failed to update data. Please try again.",
              successMsg: "",
              loadingServices: false,
            },
            () => {
              setTimeout(() => {
                this.setState({ errorMsg: "", redirect: false });
              }, 3000);
            }
          );
        }
      } catch (error) {
        console.log("Error uploading image:", error);
        this.setState({
          errorMsg: "An error occurred. Please try again.",
          successMsg: "",
          loadingServices: false,
        });
      }
    } else {
      console.log("Form has errors, fix them before submitting");
      this.setState({
        errorMsg: "An error occurred. Please try again.",
        successMsg: "",
        loadingServices: false,
      });
    }
  };

  openImageModal = (imageUrls) => {
    this.setState({
      isImageModalOpen: true,
      modalImageUrls: imageUrls,
      currentImageIndex: 0,
    });
  };

  closeImageModal = () => {
    this.setState({
      isImageModalOpen: false,
      modalImageUrls: [],
      currentImageIndex: 0,
    });
  };

  goToPreviousImage = () => {
    this.setState((prevState) => ({
      currentImageIndex: prevState.currentImageIndex - 1,
    }));
  };

  goToNextImage = () => {
    this.setState((prevState) => ({
      currentImageIndex: prevState.currentImageIndex + 1,
    }));
  };

  removeImage = async (imageId, sub_service_id) => {
    const { token, userId, userAssignServices } = this.state;

    this.setState({ loadingServices: true });

    const confirmChange = window.confirm(
      "Are you sure you want to delete this image?"
    );

    if (!confirmChange) {
      return;
    }

    try {
      const { OnboardingDeletedServiceImage } = adminendPoints;
      const method = "POST";
      const body = {
        token,
        userid: userId,
        id: imageId,
        sub_service_id: sub_service_id,
      };
      const contentType = "application/json";

      const response = await APICaller(
        OnboardingDeletedServiceImage,
        method,
        body,
        contentType,
        token
      );

      const { status } = response.data;

      if (status === 200) {
        const updatedUserAssignServices = userAssignServices.map((service) => {
          if (service.sub_services) {
            service.sub_services = service.sub_services.map((subService) => {
              if (subService.sub_service_id === sub_service_id) {
                subService.AssignFileName = subService.AssignFileName.filter(
                  (fileName) => fileName._id !== imageId
                );
              }
              return subService;
            });
          }
          return service;
        });

        this.setState(
          {
            userAssignServices: updatedUserAssignServices,
            successMsg: "Image Deleted successfully.",
            errorMsg: "",
            loadingServices: false,
          },
          () => {
            setTimeout(() => {
              this.setState({ successMsg: "", redirect: true });
            }, 5000);
          }
        );
      } else {
        console.error("Failed to remove image");
        this.setState({ errorMsg: "Failed to remove image" });
      }
    } catch (error) {
      console.error("Failed to remove image:", error);
      this.setState({ errorMsg: "Failed to remove image" });
    }
  };

  handleSuccClose = (e) => {
    this.setState({
      successMsg: "",
    });
  };

  handleErrorClose = (e) => {
    this.setState({
      errorMsg: "",
    });
  };

  render() {
    const {
      loadingServices,
      redirect,
      successMsg,
      errorMsg,
      selectedService,
      subServices,
      services,
      errors,
      radioSelections,
      userAssignServices,
      featured_image_url,
      isImageModalOpen,
      modalImageUrls,
      currentImageIndex,
      token,
    } = this.state;

    if (!token) {
      return <Navigate to="/" />;
    }

    if (redirect) {
      return <Navigate to="/ThankYou" />;
    }

    const menServices = services.filter(
      (service) => service.service_for === "Male"
    );
    const womenServices = services.filter(
      (service) => service.service_for === "Female"
    );
    const bothServices = services.filter(
      (service) => service.service_for === "Both"
    );

    return (
      <div>
        <Header />
        {loadingServices && <div className="loader"></div>}
        <section className="common-content">
          <form>
            <div className="container">
              <div className="form-area treatment-section customForm">
                <div className="form-body">
                  <div className="section-title">
                    <h3>Let's start with your services</h3>
                  </div>
                  <div className="row">
                    <div className="col-md-3 col-sm-12 col-xs-12">
                      <div className="mb-3">
                        <div className="serviceSides">
                          <h4>Services for Men</h4>
                          {menServices.map((service) => (
                            <label
                              key={service._id}
                              className={`serviceSide ${
                                userAssignServices.some(
                                  (assign) => assign.service_id === service._id
                                )
                                  ? "active-check"
                                  : ""
                              }`}
                            >
                              <input
                                className="child-checkbox"
                                type="checkbox"
                                value={service.service_name}
                                onClick={() =>
                                  this.handleServiceSelect(service._id)
                                }
                              />
                              <span>
                                <b>
                                  <i
                                    className="fa fa-check"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  {service.service_name}
                                </b>
                              </span>
                            </label>
                          ))}
                        </div>
                        <div className="serviceSides">
                          <h4>Services for Women</h4>
                          {womenServices.map((service) => (
                            <label
                              key={service._id}
                              className={`serviceSide ${
                                userAssignServices.some(
                                  (assign) => assign.service_id === service._id
                                )
                                  ? "active-check"
                                  : ""
                              }`}
                            >
                              <input
                                className="child-checkbox"
                                type="checkbox"
                                value={service.service_name}
                                onClick={() =>
                                  this.handleServiceSelect(service._id)
                                }
                              />
                              <span>
                                <b>
                                  <i
                                    className="fa fa-check"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  {service.service_name}
                                </b>
                              </span>
                            </label>
                          ))}
                        </div>
                        <div className="serviceSides">
                          <h4>Services for Both</h4>
                          {bothServices.map((service) => (
                            <label
                              key={service._id}
                              className={`serviceSide ${
                                userAssignServices.some(
                                  (assign) => assign.service_id === service._id
                                )
                                  ? "active-check"
                                  : ""
                              }`}
                            >
                              <input
                                className="child-checkbox"
                                type="checkbox"
                                value={service.service_name}
                                onClick={() =>
                                  this.handleServiceSelect(service._id)
                                }
                              />
                              <span>
                                <b>
                                  <i
                                    className="fa fa-check"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  {service.service_name}
                                </b>
                              </span>
                            </label>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-9 col-sm-12 col-xs-12">
                      <div className="mb-3">
                        <div className="treatement">
                          <div className="1treatment-col childCol custom-form">
                            {selectedService &&
                              subServices.map((subService, index) => {
                                const isLastItem =
                                  index === subServices.length - 1;
                                const isAssigned = userAssignServices.some(
                                  (service) =>
                                    service.sub_services.some(
                                      (assign) =>
                                        assign.sub_service_id === subService._id
                                    )
                                );

                                let assignFileNames = [];

                                if (isAssigned) {
                                  const assignedService =
                                    userAssignServices.find((service) =>
                                      service.sub_services.some(
                                        (assign) =>
                                          assign.sub_service_id ===
                                          subService._id
                                      )
                                    );

                                  if (isAssigned) {
                                    const assignedService =
                                      userAssignServices.find((service) =>
                                        service.sub_services.some(
                                          (assign) =>
                                            assign.sub_service_id ===
                                            subService._id
                                        )
                                      );

                                    if (assignedService) {
                                      const assignedSubService =
                                        assignedService.sub_services.find(
                                          (assign) =>
                                            assign.sub_service_id ===
                                            subService._id
                                        );

                                      if (
                                        assignedSubService &&
                                        assignedSubService.AssignFileName
                                      ) {
                                        assignFileNames = [];

                                        assignedSubService.AssignFileName.forEach(
                                          (fileName) => {
                                            assignFileNames.push({
                                              fileName,
                                              sub_service_id: subService._id,
                                            });
                                          }
                                        );
                                      }
                                    }
                                  }
                                }

                                return (
                                  <div
                                    key={index}
                                    className={
                                      isLastItem
                                        ? "treatment-col childCol custom-form"
                                        : "treatment-col childCol custom-form"
                                    }
                                  >
                                    <ul>
                                      <li>
                                        <label className="custom-radio">
                                          <input
                                            className="treatRadio"
                                            type="radio"
                                            name={`selectedRadio${index}`}
                                            value={index}
                                            checked={!!radioSelections[index]}
                                            onChange={() =>
                                              this.handleRadioChange(index)
                                            }
                                          />
                                          <span className="checkmark"></span>
                                        </label>
                                        {errors[`selectedRadio${index}`] && (
                                          <span className="error">
                                            {errors[`selectedRadio${index}`]}
                                          </span>
                                        )}
                                      </li>

                                      <li>
                                        <img
                                          src={`https://mystylist-media.s3.amazonaws.com/${subService.fileName}`}
                                          alt={subService.sub_service_name}
                                        />
                                        <span>
                                          {subService.sub_service_name}
                                        </span>
                                      </li>

                                      <li>
                                        <h6>Your Price</h6>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Add price (₹)"
                                          name="price"
                                          value={subService.price}
                                          onChange={(e) =>
                                            this.handleInputChange(e, index)
                                          }
                                        />
                                        {errors[`price${index}`] && (
                                          <span className="error">
                                            {errors[`price${index}`]}
                                          </span>
                                        )}
                                      </li>
                                      <li>
                                        <h6>Time Taken</h6>
                                        <div className="treat-select">
                                          <select
                                            className="form-control"
                                            name="hours"
                                            value={subService.time_taken.hours}
                                            onChange={(e) =>
                                              this.handleInputChange(e, index)
                                            }
                                          >
                                            <option value="">HH</option>
                                            {[...Array(13).keys()]
                                              .slice(1)
                                              .map((hour) => (
                                                <option key={hour} value={hour}>
                                                  {hour}
                                                </option>
                                              ))}
                                          </select>
                                          {errors[`hours${index}`] && (
                                            <span className="error">
                                              {errors[`hours${index}`]}
                                            </span>
                                          )}
                                          <select
                                            className="form-control"
                                            name="minutes"
                                            value={
                                              subService.time_taken.minutes ||
                                              "0"
                                            }
                                            onChange={(e) =>
                                              this.handleInputChange(e, index)
                                            }
                                          >
                                            <option value="">MM</option>
                                            {[...Array(60).keys()].map(
                                              (min) => (
                                                <option key={min} value={min}>
                                                  {min}
                                                </option>
                                              )
                                            )}
                                          </select>

                                          {errors[`minutes${index}`] && (
                                            <span className="error">
                                              {errors[`minutes${index}`]}
                                            </span>
                                          )}
                                        </div>
                                      </li>
                                      <li>
                                        <h6>Product Used</h6>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Enter here..."
                                          name="productUsed"
                                          value={subService.productUsed}
                                          onChange={(e) =>
                                            this.handleInputChange(e, index)
                                          }
                                        />
                                        {errors[`productUsed${index}`] && (
                                          <span className="error">
                                            {errors[`productUsed${index}`]}
                                          </span>
                                        )}
                                      </li>

                                      <li>
                                        <h6>Upload photo</h6>
                                        <div className="file-upload-wrapper">
                                          <input
                                            type="file"
                                            className="file-upload"
                                            multiple
                                            onChange={(e) =>
                                              this.handleFileChange(e, index)
                                            }
                                            disabled={
                                              assignFileNames.length >= 6
                                            }
                                          />
                                          <label
                                            htmlFor="file-upload"
                                            className="file-upload-label"
                                          >
                                            <img
                                              src="assets/img/salon.png"
                                              alt="Upload"
                                            />
                                            Upload here..
                                          </label>
                                          {errors[`file${index}`] && (
                                            <span className="error">
                                              {errors[`file${index}`]}
                                            </span>
                                          )}
                                        </div>
                                      </li>
                                      <li>
                                        <h6>Best Service</h6>
                                        <select
                                          className="form-control"
                                          name="best_service"
                                          value={subService.best_service}
                                          onChange={(e) =>
                                            this.handleInputChange(e, index)
                                          }
                                        >
                                          <option value="">
                                            Select Best Service
                                          </option>
                                          <option value="No">No</option>
                                          <option value="Yes">Yes</option>
                                        </select>
                                        {errors[`best_service${index}`] && (
                                          <span className="error">
                                            {errors[`best_service${index}`]}
                                          </span>
                                        )}
                                      </li>
                                      <li>
                                        <button
                                          className="btn-green cusBtn"
                                          onClick={(e) =>
                                            this.handleSubmit(e, index)
                                          }
                                        >
                                          Save
                                        </button>
                                      </li>
                                    </ul>
                                    <div className="image-treat">
                                      {assignFileNames.map(
                                        (file, fileIndex) => (
                                          <div
                                            className="imgWrap"
                                            key={file.sub_service_id}
                                          >
                                            <i
                                              className="fa-solid fa-trash-can"
                                              onClick={() =>
                                                this.removeImage(
                                                  file.fileName._id,
                                                  file.sub_service_id
                                                )
                                              }
                                            ></i>
                                            <img
                                              style={{ height: "53px" }}
                                              src={`${featured_image_url}/${file.fileName.image}`}
                                              alt={`Image ${fileIndex + 1}`}
                                              title={`Image ${fileIndex + 1}`}
                                              onClick={() =>
                                                this.openImageModal(
                                                  assignFileNames.map(
                                                    (fileName) =>
                                                      `${featured_image_url}/${fileName.fileName.image}`
                                                  )
                                                )
                                              }
                                            />
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-container">
              <div className="container">
                <div className="next-prv-button">
                  <Link to="/Amenities" className="nextBtn">
                    Back
                  </Link>
                </div>
              </div>
            </div>
          </form>
        </section>
        {isImageModalOpen && (
          <div
            className="modal"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-body">
                  <span className="close" onClick={this.closeImageModal}>
                    &times;
                  </span>
                  <img
                    src={modalImageUrls[currentImageIndex]}
                    alt="View Image"
                  />
                  <div>
                    <button
                      onClick={this.goToPreviousImage}
                      disabled={currentImageIndex === 0}
                    >
                      Previous
                    </button>
                    <button
                      onClick={this.goToNextImage}
                      disabled={currentImageIndex === modalImageUrls.length - 1}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="error-success">
          {successMsg && (
            <div className="sucessMsg">
              <div className="msgCon">
                <i className="fa fa-check" aria-hidden="true"></i> {successMsg}
              </div>
              <div className="msgClose" onClick={this.handleSuccClose}>
                <i className="fa fa-times" aria-hidden="true"></i>
              </div>
            </div>
          )}

          {errorMsg && (
            <div className="errorMsg">
              <div className="msgCon">
                <i className="fa fa-times" aria-hidden="true"></i> Error:{" "}
                {errorMsg}
              </div>
              <div className="msgClose" onClick={this.handleErrorClose}>
                <i className="fa fa-times" aria-hidden="true"></i>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Treatment;
