import React, { Component } from "react";
import { Link, Navigate } from "react-router-dom";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";
import Header from "../Header/index";

class Location extends Component {
  constructor(props) {
    super(props);
    const retrievedObject = getLocalStorageItem("onboardingDetails");

    this.state = {
      userId: retrievedObject ? retrievedObject.userId : "",
      token: retrievedObject ? retrievedObject.token : "",
      selectedState: "",
      selectedDistrict: "",
      selectedCity: "",
      states: [],
      districts: [],
      cities: [],
      businessName: "",
      address: "",
      pincode: "",
      successMsg: "",
      errorMsg: "",
      redirect: false,
      errors: {},
      latitude: "",
      longitude: "",
      loading: false,
      experience: "",
      gender: "",
      fullName: "",
      email: "",
      homeVisit: "",
      mobileNumber: "",
      freelancer: "",
      salon_name: "",
      user_information: [],
    };
  }

  componentDidMount() {
    this.initializeData();
  }

  initializeData = async () => {
    await this.fetchUserData();
    await this.fetchStates();
    const { selectedState, selectedDistrict } = this.state;
    if (selectedState.value) {
      await this.fetchDistricts(selectedState.value);
    }
    if (selectedDistrict.value) {
      await this.fetchCities(selectedDistrict.value);
    }
  };

  fetchUserData = async () => {
    this.setState({ loading: true });
    const { token, userId } = this.state;
    const { AdminExpertUserDetails } = adminendPoints;
    const method = "POST";
    const contentType = "application/json";

    try {
      const body = { token, userid: userId };
      const response = await APICaller(
        AdminExpertUserDetails,
        method,
        body,
        contentType,
        token
      );

      const { status, user } = response.data;

      if (status === 200 && user) {
        const userInformation =
          user.user_information && user.user_information[0]
            ? user.user_information[0]
            : {};

        this.setState({
          loading: false,
          gender: user.gender,
          salon_name: user.salon_name,
          fullName: user.name,
          email: user.email,
          mobileNumber: user.phone,
          homeVisit: user.homeVisit,
          freelancer: user.freelancer,
          experience: user.user_information[0].experience,
          user_information: [
            {
              aboutMe: userInformation.aboutMe || "",
              experience: userInformation.experience || "",
              course: userInformation.course || "",
              course_name: userInformation.course_name || "",
              facebook_url: userInformation.facebook_url || "",
              instagram_url: userInformation.instagram_url || "",
            },
          ],
          selectedState:
            user.state && user.state[0]
              ? {
                  value: user.state[0].state_id,
                  label: user.state[0].state_name,
                }
              : "",
          selectedDistrict:
            user.district && user.district[0]
              ? {
                  value: user.district[0].district_id,
                  label: user.district[0].district_name,
                }
              : "",
          selectedCity:
            user.city && user.city[0]
              ? {
                  value: user.city[0].city_id,
                  label: user.city[0].city_name,
                }
              : "",
          address:
            user.addresses && user.addresses[0]
              ? user.addresses[0].address.sector
              : "",
          pincode:
            user.addresses && user.addresses[0]
              ? user.addresses[0].address.pinCode
              : "",
          latitude:
            user.addresses &&
            user.addresses[0] &&
            user.addresses[0].address.location
              ? user.addresses[0].address.location.coordinates[0]
              : "",
          longitude:
            user.addresses &&
            user.addresses[0] &&
            user.addresses[0].address.location
              ? user.addresses[0].address.location.coordinates[1]
              : "",
        });
      } else {
        console.error("Failed to fetch user data");
        this.setState({
          errorMsg: "Failed to fetch user data",
          loading: false,
        });
      }
    } catch (error) {
      console.error("Failed to fetch user data:", error);
      this.setState({ errorMsg: "Failed to fetch user data", loading: false });
    }
  };

  fetchStates = async () => {
    const { token } = this.state;
    const { AdminStateList } = adminendPoints;
    const method = "GET";
    const contentType = "application/json";
    const stateUrl = `${AdminStateList}`;

    try {
      const statesResponse = await APICaller(
        stateUrl,
        method,
        "",
        contentType,
        token
      );

      const { states } = statesResponse.data;
      const statesData = states.map((state) => ({
        value: state._id,
        label: state.state_name,
      }));

      this.setState({ states: statesData });
    } catch (error) {
      console.error("Error fetching states:", error);
      this.setState({
        errorMsg: "An error occurred while fetching states. Please try again.",
      });
    }
  };

  fetchDistricts = async (stateIds) => {
    const { token } = this.state;
    const { AdmingeAlltDistrictByStateName } = adminendPoints;
    const method = "POST";
    const contentType = "application/json";

    try {
      const body = { stateIds };
      const response = await APICaller(
        AdmingeAlltDistrictByStateName,
        method,
        body,
        contentType,
        token
      );

      const { status, data } = response.data;

      if (status === 200) {
        const districtsData = data.map((district) => ({
          value: district._id,
          label: district.district_name,
        }));
        this.setState({ districts: districtsData, cities: [] });
      }
    } catch (error) {
      console.error("Error fetching districts:", error);
      this.setState({
        errorMsg:
          "An error occurred while fetching districts. Please try again.",
      });
    }
  };

  fetchCities = async (districtValue) => {
    const { token } = this.state;
    const { AdmingeAlltDistricByCityName } = adminendPoints;
    const method = "POST";
    const contentType = "application/json";

    try {
      const body = {
        districtds: districtValue,
      };

      const cityResponse = await APICaller(
        AdmingeAlltDistricByCityName,
        method,
        body,
        contentType,
        token
      );

      const { data, status } = cityResponse.data;

      if (status === 200) {
        const citiesData = data.map((city) => ({
          value: city._id,
          label: city.city_name,
        }));
        this.setState({ cities: citiesData });
      } else {
        console.error("Failed to fetch cities");
      }
    } catch (error) {
      console.error("Error fetching cities:", error);
      this.setState({
        errorMsg: "An error occurred while fetching cities. Please try again.",
      });
    }
  };

  handleStateChange = (e) => {
    const selectedState = e.target.value;
    const stateLabel = e.target.options[e.target.selectedIndex].text;
    this.setState(
      {
        selectedState: { value: selectedState, label: stateLabel },
        selectedDistrict: "",
        selectedCity: "",
        districts: [],
        cities: [],
      },
      () => {
        if (selectedState) {
          this.fetchDistricts(selectedState);
        }
      }
    );
  };

  handleDistrictChange = (e) => {
    const selectedDistrict = e.target.value;
    const districtLabel = e.target.options[e.target.selectedIndex].text;
    this.setState(
      {
        selectedDistrict: { value: selectedDistrict, label: districtLabel },
        selectedCity: "",
        cities: [],
      },
      () => {
        if (selectedDistrict) {
          this.fetchCities(selectedDistrict);
        }
      }
    );
  };

  handleCityChange = (e) => {
    const selectedCity = e.target.value;
    const cityLabel = e.target.options[e.target.selectedIndex].text;
    this.setState({ selectedCity: { value: selectedCity, label: cityLabel } });
  };

  validateForm = () => {
    const {
      gender,
      freelancer,
      fullName,
      experience,
      address,
      homeVisit,
      selectedState,
      selectedDistrict,
      selectedCity,
      pincode,
      salon_name,
    } = this.state;

    const errors = {};

    if (!fullName) errors.fullName = "Name is required.";
    if (!salon_name) errors.salon_name = "Salon Name is required.";
    if (!gender) errors.gender = "Gender is required.";
    if (!freelancer) errors.freelancer = "Freelancer is required.";
    if (!experience) errors.experience = "Experience is required.";
    if (!homeVisit) errors.homeVisit = "HomeVisit is required.";
    if (!address) errors.address = "Address is required.";
    if (!selectedState || !selectedState.value)
      errors.selectedState = "State is required.";
    if (!selectedDistrict || !selectedDistrict.value)
      errors.selectedDistrict = "District is required.";
    if (!selectedCity || !selectedCity.value)
      errors.selectedCity = "City is required.";
    if (!pincode) {
      errors.pincode = "Pincode is required.";
    } else if (!/^\d{6}$/.test(pincode)) {
      errors.pincode = "Pincode must be a 6 digit number.";
    }

    console.log("Validation errors:", errors); // Debugging log

    this.setState({ errors });

    return Object.keys(errors).length === 0;
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const {
      userId,
      salon_name,
      freelancer,
      address,
      selectedState,
      selectedDistrict,
      selectedCity,
      pincode,
      latitude,
      longitude,
      fullName,
      email,
      homeVisit,
      experience,
      gender,
      user_information,
    } = this.state;

    if (!this.validateForm()) {
      return;
    }

    const body = {
      id: userId,
      salon_name: salon_name,
      gender: gender,
      name: fullName,
      email: email,
      homeVisit: homeVisit,
      experience: experience,
      freelancer: freelancer,
      state: {
        value: selectedState.value,
        label: selectedState.label,
      },
      district: {
        value: selectedDistrict.value,
        label: selectedDistrict.label,
      },
      city: {
        value: selectedCity.value,
        label: selectedCity.label,
      },
      addresses: [
        {
          address: {
            addressType: "Home",
            houseNumber: "",
            sector: address,
            pinCode: pincode,
            landmark: "",
            location: {
              type: "Point",
              coordinates: [parseFloat(longitude), parseFloat(latitude)],
            },
          },
          isDefault: false,
        },
      ],

      user_information: [
        {
          aboutMe: user_information[0].aboutMe || "",
          experience: experience || user_information[0].experience,
          course: user_information[0].course || "No",
          course_name: user_information[0].course_name || "",
          facebook_url: user_information[0].facebook_url || "",
          instagram_url: user_information[0].instagram_url || "",
        },
      ],
    };

    const { OnboardingUpdateBussformation } = adminendPoints;
    const method = "POST";
    const contentType = "application/json";

    this.setState({ loading: true });

    try {
      const response = await APICaller(
        OnboardingUpdateBussformation,
        method,
        body,
        contentType
      );

      const { status, data } = response;

      if (data && status === 200) {
        this.setState(
          {
            successMsg: "Location updated successfully.",
            errorMsg: "",
            loading: false,
          },
          () => {
            setTimeout(() => {
              this.setState({ successMsg: "", redirect: true });
            }, 5000);
          }
        );
      } else {
        this.setState(
          {
            errorMsg: "Failed to update data. Please try again.",
            successMsg: "",
            loading: false, // Set loading to false
          },
          () => {
            setTimeout(() => {
              this.setState({ errorMsg: "" });
            }, 3000);
          }
        );
      }
    } catch (error) {
      this.setState({
        errorMsg: "Error updating data. Please try again.",
        successMsg: "",
        loading: false, // Set loading to false
      });
    }
  };

  handleSuccClose = (e) => {
    this.setState({
      successMsg: "",
    });
  };

  handleErrorClose = (e) => {
    this.setState({
      errorMsg: "",
    });
  };

  render() {
    const {
      states,
      districts,
      cities,
      selectedState,
      selectedDistrict,
      selectedCity,
      successMsg,
      errorMsg,
      redirect,
      errors,
      pincode,
      address,
      loading,
      experience,
      fullName,
      email,
      homeVisit,
      freelancer,
      gender,
      token,
      salon_name,
    } = this.state;

    if (!token) {
      return <Navigate to="/" />;
    }

    if (redirect) {
      return <Navigate to="/Profile" />;
    }

    const options = Array.from({ length: 30 }, (_, index) => index + 1);

    return (
      <div>
        <Header />
        <div className="content-area">
          <section className="common-content">
            <form className="custom-form" onSubmit={this.handleSubmit}>
              <div className="container">
                <div className="form-area custom-width-850 customForm">
                  <div className="form-body location-form">
                    <div className="section-title">
                      <h3>Create Your Profile</h3>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <div className="mb-3">
                          <label>Stylist Name*</label>
                          <input
                            type="text"
                            name="fullName"
                            value={fullName}
                            onChange={this.handleInputChange}
                            className={`form-control ${
                              errors.fullName ? "is-invalid" : ""
                            }`}
                            placeholder="Enter here..."
                          />
                          {errors.fullName && (
                            <div className="invalid-feedback">
                              {errors.fullName}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <div className="mb-3">
                          <label>Email </label>
                          <input
                            type="email"
                            name="email"
                            value={email}
                            onChange={this.handleInputChange}
                            className={`form-control ${
                              errors.email ? "is-invalid" : ""
                            }`}
                            placeholder="Enter here..."
                          />
                          {errors.email && (
                            <div className="invalid-feedback">
                              {errors.email}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-4 col-xs-12">
                        <div className="mb-3">
                          <label>Home Visit*</label>
                          <select
                            name="homeVisit"
                            value={homeVisit}
                            onChange={this.handleInputChange}
                            className={`form-control ${
                              errors.homeVisit ? "is-invalid" : ""
                            }`}
                          >
                            <option value="">Please select</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                          {errors.homeVisit && (
                            <div className="invalid-feedback">
                              {errors.homeVisit}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-4 col-xs-12">
                        <div className="mb-3">
                          <label>Are you a Freelancer*</label>
                          <select
                            name="freelancer"
                            value={freelancer}
                            onChange={this.handleInputChange}
                            className={`form-control ${
                              errors.freelancer ? "is-invalid" : ""
                            }`}
                          >
                            <option value="">Please select</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                          {errors.freelancer && (
                            <div className="invalid-feedback">
                              {errors.freelancer}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-4 col-xs-12">
                        <div className="mb-3">
                          <label>Salon Name*</label>
                          <input
                            type="text"
                            name="salon_name"
                            value={salon_name}
                            onChange={this.handleInputChange}
                            className={`form-control ${
                              errors.salon_name ? "is-invalid" : ""
                            }`}
                            placeholder="Enter here..."
                          />
                          {errors.salon_name && (
                            <div className="invalid-feedback">
                              {errors.salon_name}
                            </div>
                          )}
                        </div>
                      </div>

                      {freelancer === "No" && (
                        <div className="col-md-12">
                          <div className="mb-3">
                            <label>Salon Address</label>
                            <input
                              type="text"
                              className="form-control"
                              value={address}
                              name="address"
                              placeholder="Address here..."
                              onChange={this.handleInputChange}
                            />
                            {errors.address && (
                              <div className="text-danger">
                                {errors.address}
                              </div>
                            )}
                          </div>
                        </div>
                      )}

                      <div className="col-md-4 col-sm-4 col-xs-12">
                        <div className="mb-3">
                          <label>Gender*</label>
                          <select
                            name="gender"
                            value={gender}
                            onChange={this.handleInputChange}
                            className={`form-control ${
                              errors.gender ? "is-invalid" : ""
                            }`}
                          >
                            <option value="">Please select</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                          </select>
                          {errors.gender && (
                            <div className="invalid-feedback">
                              {errors.gender}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-4 col-xs-12">
                        <div className="mb-3">
                          <label>Experience*</label>
                          <select
                            name="experience"
                            value={experience}
                            onChange={this.handleInputChange}
                            className={`form-control ${
                              errors.experience ? "is-invalid" : ""
                            }`}
                          >
                            <option value="">Please select</option>
                            {options.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                          {errors.experience && (
                            <div className="invalid-feedback">
                              {errors.experience}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-4 col-xs-12">
                        <div className="mb-3">
                          <label>State*</label>
                          <select
                            className={`form-control ${
                              errors.selectedState ? "is-invalid" : ""
                            }`}
                            name="selectedState"
                            value={selectedState.value}
                            onChange={this.handleStateChange}
                          >
                            <option value="">Select</option>
                            {states.map((state) => (
                              <option key={state.value} value={state.value}>
                                {state.label}
                              </option>
                            ))}
                          </select>
                          {errors.selectedState && (
                            <div className="invalid-feedback">
                              {errors.selectedState}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-4 col-xs-12">
                        <div className="mb-3">
                          <label>District*</label>
                          <select
                            className={`form-control ${
                              errors.selectedDistrict ? "is-invalid" : ""
                            }`}
                            name="selectedDistrict"
                            value={selectedDistrict.value}
                            onChange={this.handleDistrictChange}
                            disabled={!selectedState.value}
                          >
                            <option value="">Select</option>
                            {districts.map((district) => (
                              <option
                                key={district.value}
                                value={district.value}
                              >
                                {district.label}
                              </option>
                            ))}
                          </select>
                          {errors.selectedDistrict && (
                            <div className="invalid-feedback">
                              {errors.selectedDistrict}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-4 col-xs-12">
                        <div className="mb-3">
                          <label>City*</label>
                          <select
                            className={`form-control ${
                              errors.selectedCity ? "is-invalid" : ""
                            }`}
                            name="selectedCity"
                            value={selectedCity.value}
                            onChange={this.handleCityChange}
                            disabled={!selectedDistrict.value}
                          >
                            <option value="">Select</option>
                            {cities.map((city) => (
                              <option key={city.value} value={city.value}>
                                {city.label}
                              </option>
                            ))}
                          </select>
                          {errors.selectedCity && (
                            <div className="invalid-feedback">
                              {errors.selectedCity}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-4 col-xs-12">
                        <div className="mb-3">
                          <label>Pincode*</label>
                          <input
                            type="text"
                            value={pincode}
                            className={`form-control ${
                              errors.pincode ? "is-invalid" : ""
                            }`}
                            name="pincode"
                            placeholder="Enter here"
                            onChange={this.handleInputChange}
                          />
                          {errors.pincode && (
                            <div className="invalid-feedback">
                              {errors.pincode}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {loading && <div className="loader"></div>}
                  </div>
                </div>
              </div>
              <div className="footer-container">
                <div className="container">
                  <div
                    className="next-prv-button"
                    style={{ justifyContent: "end" }}
                  >
                    <div className="foo-btns">
                      <button type="submit" className="nextBtn">
                        Save
                      </button>
                      <Link to="/Profile" className="nextBtn">
                        Next
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </section>
        </div>
        <div className="error-success">
          {successMsg && (
            <div className="sucessMsg">
              <div className="msgCon">
                <i className="fa fa-check" aria-hidden="true"></i> {successMsg}
              </div>
              <div className="msgClose" onClick={this.handleSuccClose}>
                <i className="fa fa-times" aria-hidden="true"></i>
              </div>
            </div>
          )}

          {errorMsg && (
            <div className="errorMsg">
              <div className="msgCon">
                <i className="fa fa-times" aria-hidden="true"></i> Error:{" "}
                {errorMsg}
              </div>
              <div className="msgClose" onClick={this.handleErrorClose}>
                <i className="fa fa-times" aria-hidden="true"></i>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Location;
