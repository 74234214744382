// src/store/actions/adminActions.js

import ActionTypes from "../constants";

export const storeAdminDetail = (adminDetails) => ({
  type: ActionTypes.STORE_ADMIN_DETAIL,
  payload: adminDetails,
});

export const adminLogout = () => ({
  type: ActionTypes.ADMIN_LOGOUT,
});

export const adminLoginSuccess = (adminDetails) => ({
  type: ActionTypes.ADMIN_LOGIN_SUCCESS,
  payload: adminDetails,
});

export const adminLoginFailure = (errorMessage) => ({
  type: ActionTypes.ADMIN_LOGIN_FAILURE,
  payload: errorMessage,
});
