import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import {
  setLocalStorageItem,
  getLocalStorageItem,
} from "../../store/localStorage";
import Header from "../Header/index";

class Otp extends Component {
  constructor(props) {
    super(props);

    const retrievedObject = getLocalStorageItem("onboardingDetails");

    this.state = {
      userId: retrievedObject ? retrievedObject.userId : "",
      token: retrievedObject ? retrievedObject.token : "",
      otp: ["", "", "", "", "", ""],
      successMsg: "",
      errorMsg: "",
      redirect: false,
      errors: null,
      isButtonDisabled: true,
      loading: false,
      phone:
        retrievedObject && retrievedObject.phone ? retrievedObject.phone : "", //
    };
  }

  validateOtp = () => {
    const { otp } = this.state;
    let isValid = true;
    const newErrors = {};

    if (otp.some((digit) => digit === "")) {
      newErrors.otp = "OTP is required.";
      isValid = false;
    }

    return { isValid, newErrors };
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const { isValid, newErrors } = this.validateOtp();

    if (!isValid) {
      this.setState({ errorMsg: "", successMsg: "", errors: newErrors }, () => {
        setTimeout(() => {
          this.setState({ errors: null });
        }, 5000);
      });
      return;
    }

    this.setState({ loading: true, errors: null });

    const { otp, phone } = this.state;
    const { OnboardingOtp } = adminendPoints;
    const method = "POST";
    const body = {
      otp: otp.join(""),
      phone,
    };
    const contentType = "application/json";

    try {
      const response = await APICaller(
        OnboardingOtp,
        method,
        body,
        contentType
      );

      const { status, data } = response;

      if (status === 200) {
        setLocalStorageItem("onboardingDetails", data);
        this.setState(
          {
            successMsg: "OTP verified successfully.",
            errorMsg: "",
            loading: false,
          },
          () => {
            setTimeout(() => {
              this.setState({ successMsg: "", redirect: true });
            }, 2000);
          }
        );
      } else if (
        status === 400 &&
        data &&
        data.message === "Invalid details."
      ) {
        this.setState(
          {
            errorMsg: "Invalid OTP. Please try again.",
            successMsg: "",
            loading: false,
          },
          () => {
            setTimeout(() => {
              this.setState({ errorMsg: "", redirect: false });
            }, 3000);
          }
        );
      } else {
        throw new Error("Unexpected error occurred.");
      }
    } catch (error) {
      if (error.status === 400) {
        this.setState(
          {
            errorMsg: "Invalid OTP. Please try again.",
            successMsg: "",
            loading: false,
          },
          () => {
            setTimeout(() => {
              this.setState({ errorMsg: "", redirect: false });
            }, 3000);
          }
        );
      }
    }
  };

  handleOtpChange = (e, index) => {
    const { value } = e.target;
    if (/^\d*$/.test(value) && value.length <= 1) {
      const newOtp = [...this.state.otp];
      newOtp[index] = value;
      this.setState({ otp: newOtp }, () => {
        if (value && index < 5) {
          document.getElementById(`otp-input-${index + 1}`).focus();
        } else if (!value && index > 0) {
          document.getElementById(`otp-input-${index - 1}`).focus();
        }
        this.checkIfAllFieldsFilled();
      });
    }
  };

  checkIfAllFieldsFilled = () => {
    const { otp } = this.state;
    const isButtonDisabled = otp.some((digit) => digit === "");
    this.setState({ isButtonDisabled });
  };

  resendOtp = async () => {
    const { phone } = this.state;

    const { OnboardingLogin } = adminendPoints;
    const method = "POST";
    const body = {
      phone,
    };
    const contentType = "application/json";
    this.setState({ loading: true });
    try {
      const response = await APICaller(
        OnboardingLogin,
        method,
        body,
        contentType
      );

      const { status } = response;

      if (status === 200) {
        this.setState({
          successMsg: "OTP resent successfully.",
          errorMsg: "",
          loading: false,
        });
      } else {
        throw new Error("Failed to resend OTP.");
      }
    } catch (error) {
      this.setState({
        errorMsg: "Failed to resend OTP. Please try again.",
        successMsg: "",
        loading: false,
      });
    }
  };

  handleSuccClose = (e) => {
    this.setState({
      successMsg: "",
    });
  };

  handleErrorClose = (e) => {
    this.setState({
      errorMsg: "",
    });
  };

  render() {
    const {
      otp,
      errors,
      redirect,
      successMsg,
      errorMsg,
      isButtonDisabled,
      token,
      phone,
    } = this.state;

    if (!token) {
      return <Navigate to="/" />;
    }

    if (redirect) {
      return <Navigate to="/Location" />;
    }

    return (
      <div>
        <Header />
        <div className="content-area">
          <section className="common-content">
            <div className="container">
              <div className="form-area custom-width customForm">
                <div className="form-header">
                  <h4 className="mb-3 alignCenter">Create your profile</h4>
                </div>
                <div className="form-body otp-form">
                  <span className="subSteps">STEP 1 OF 2</span>
                  <h3>Confirm your phone number</h3>
                  <label className="sub-title">
                    Enter the 6-digit code sent to +91 {phone}
                  </label>
                  <form onSubmit={this.handleSubmit}>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="mb-3">
                          <div id="inputs" className="inputs">
                            {otp.map((digit, index) => (
                              <input
                                key={index}
                                id={`otp-input-${index}`}
                                className="input"
                                type="text"
                                inputMode="numeric"
                                maxLength="1"
                                placeholder="-"
                                value={digit}
                                onChange={(e) => this.handleOtpChange(e, index)}
                              />
                            ))}
                          </div>
                          {errors && errors.otp && (
                            <div className="text-danger">{errors.otp}</div>
                          )}
                        </div>
                        <div className="mb-3">
                          <button
                            type="submit"
                            className={`otp-button ${
                              !isButtonDisabled ? "otpHover" : ""
                            }`}
                            disabled={isButtonDisabled}
                          >
                            Continue
                          </button>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="otp-footer">
                          Didn’t get a text?
                          <button
                            className="resend-button"
                            type="button"
                            onClick={this.resendOtp}
                          >
                            Send again
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="error-success">
          {successMsg && (
            <div className="sucessMsg">
              <div className="msgCon">
                <i className="fa fa-check" aria-hidden="true"></i> {successMsg}
              </div>
              <div className="msgClose" onClick={this.handleSuccClose}>
                <i className="fa fa-times" aria-hidden="true"></i>
              </div>
            </div>
          )}

          {errorMsg && (
            <div className="errorMsg">
              <div className="msgCon">
                <i className="fa fa-times" aria-hidden="true"></i>
                {errorMsg}
              </div>
              <div className="msgClose" onClick={this.handleErrorClose}>
                <i className="fa fa-times" aria-hidden="true"></i>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    adminDetails: state.admin.adminDetails,
    phone:
      state.admin.adminDetails && state.admin.adminDetails.data
        ? state.admin.adminDetails.data.phone
        : "",
  };
};

export default connect(mapStateToProps)(Otp);
