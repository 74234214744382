import React, { Component } from "react";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";

import Header from "../Header/index";

class Salonphoto extends Component {
  constructor(props) {
    super(props);

    const retrievedObject = getLocalStorageItem("onboardingDetails");

    this.state = {
      userId: retrievedObject ? retrievedObject.userId : "",
      token: retrievedObject ? retrievedObject.token : "",
      imageFiles: [],
      preSelectedimageFiles: [],
      successMsg: "",
      errorMsg: "",
      redirect: false,
      errors: null,
      loading: false,
    };
  }

  componentDidMount() {
    this.fetchUserData();
  }

  fetchUserData = async () => {
    this.setState({ loading: true });
    const { token, userId } = this.state;

    const { AdminExpertUserDetails } = adminendPoints;
    const method = "POST";
    const contentType = "application/json";

    try {
      const body = { token, userid: userId };
      const response = await APICaller(
        AdminExpertUserDetails,
        method,
        body,
        contentType,
        token
      );

      const { status, user } = response.data;

      if (status === 200 && user) {
        this.setState({
          preSelectedimageFiles: user?.user_work_images || [],
          loading: false,
        });
      } else {
        console.error("Failed to fetch user data");
        this.setState({
          errorMsg: "Failed to fetch user data",
          loading: false,
        });
      }
    } catch (error) {
      console.error("Failed to fetch user data:", error);
      this.setState({ errorMsg: "Failed to fetch user data", loading: false });
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    this.setState({ loading: true, errors: {} });

    const { userId, imageFiles, token } = this.state;
    const { AdminUploadExpertGallery } = adminendPoints;

    const formData = new FormData();
    formData.append("id", userId);
    imageFiles.forEach((file) => {
      formData.append("user_work_images", file);
    });

    const method = "POST";
    const body = formData;

    try {
      const response = await APICaller(
        AdminUploadExpertGallery,
        method,
        body,
        {},
        token
      );

      const { status, data } = response;

      if (data && status === 200) {
        this.setState(
          {
            successMsg: "Work Image uploaded successfully.",
            errorMsg: "",
            loading: false,
          },
          () => {
            setTimeout(() => {
              this.setState({ successMsg: "", redirect: true });
            }, 5000);
          }
        );
      } else {
        this.setState(
          {
            errorMsg: "Failed to upload Work Photo. Please try again.",
            successMsg: "",
            loading: false,
          },
          () => {
            setTimeout(() => {
              this.setState({ errorMsg: "", redirect: false });
            }, 3000);
          }
        );
      }
    } catch (error) {
      console.log("error making data call --> ", error);
      this.setState({
        errorMsg:
          "An error occurred while Uploading the Work Photo. Please try again.",
        successMsg: "",
        loading: false,
      });
    }
  };

  handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    const totalImages = this.state.imageFiles.length + files.length;
    if (totalImages > 10) {
      this.setState({ errorMsg: "Maximum 10 photos can be uploaded." });
      return;
    }
    this.setState((prevState) => ({
      imageFiles: [...prevState.imageFiles, ...files],
      errorMsg: "",
    }));
  };

  handleRemoveImage = (index) => {
    this.setState((prevState) => ({
      imageFiles: prevState.imageFiles.filter((_, i) => i !== index),
    }));
  };

  handleRemoveImageSI = async (imageId) => {
    const { token, userId } = this.state;

    const confirmChange = window.confirm(
      "Are you sure you want to delete this image?"
    );

    if (!confirmChange) {
      return;
    }

    try {
      const { AdminDeleteUserWorkingImage } = adminendPoints;
      const method = "POST";
      const body = { token, userid: userId, id: imageId };
      const contentType = "application/json";

      const response = await APICaller(
        AdminDeleteUserWorkingImage,
        method,
        body,
        contentType,
        token
      );

      const { status } = response.data;

      if (status === 200) {
        this.setState(
          (prevState) => ({
            preSelectedimageFiles: prevState.preSelectedimageFiles.filter(
              (file) => file._id !== imageId
            ),
            successMsg: "Image removed successfully.",
          }),
          () => {
            setTimeout(() => {
              this.setState({ successMsg: "" });
            }, 3000);
          }
        );
      } else {
        console.error("Failed to remove image");
        this.setState({ errorMsg: "Failed to remove image" });
      }
    } catch (error) {
      console.error("Failed to remove image:", error);
      this.setState({ errorMsg: "Failed to remove image" });
    }
  };

  handleSuccClose = (e) => {
    this.setState({
      successMsg: "",
    });
  };

  handleErrorClose = (e) => {
    this.setState({
      errorMsg: "",
    });
  };

  render() {
    const {
      loading,
      redirect,
      successMsg,
      imageFiles,
      errorMsg,
      preSelectedimageFiles,
      token,
    } = this.state;

    if (!token) {
      return <Navigate to="/" />;
    }

    if (redirect) {
      return <Navigate to="/Amenities" />;
    }

    return (
      <div>
        <Header />
        <div className="content-area">
          <section className="common-content">
            <form className="custom-form" onSubmit={this.handleSubmit}>
              <div className="container">
                <div className="form-area custom-width-750 customForm">
                  <div className="form-body">
                    <div className="section-title">
                      <h3>Upload your salon photos</h3>
                    </div>

                    <div className="row">
                      <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className="mb-3">
                          <div className="salon-form" id="photo-grid">
                            <div className="photo-item">
                              <div className="file-upload-wrapper">
                                <input
                                  type="file"
                                  className="file-upload"
                                  id="photo-input"
                                  multiple
                                  accept="image/*"
                                  onChange={this.handleImageChange}
                                />
                                <label
                                  htmlFor="photo-input"
                                  className="file-upload-label"
                                >
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assets/img/salon.png"
                                    }
                                    alt="image"
                                  />
                                  Upload photos here..
                                </label>
                              </div>
                            </div>
                            {imageFiles.map((file, index) => (
                              <div className="photo-item" key={index}>
                                <div className="file-upload-wrapper">
                                  <i
                                    className="fa-solid fa-trash-can"
                                    onClick={() =>
                                      this.handleRemoveImage(index)
                                    }
                                  ></i>
                                  <img
                                    src={URL.createObjectURL(file)}
                                    alt={`image-${index}`}
                                  />
                                </div>
                              </div>
                            ))}

                            {preSelectedimageFiles.map((file, index) => (
                              <div className="photo-item" key={file._id}>
                                <div className="file-upload-wrapper">
                                  <i
                                    className="fa-solid fa-trash-can"
                                    onClick={() =>
                                      this.handleRemoveImageSI(file._id)
                                    }
                                  ></i>
                                  <img
                                    src={`https://mystylist-media.s3.amazonaws.com/${file.image}`}
                                    alt={`image-${index}`}
                                  />
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                      {loading && <div className="loader"></div>}
                    </div>
                  </div>
                </div>
              </div>

              <div className="footer-container">
                <div className="container">
                  <div className="next-prv-button">
                    <Link to="/UploadPhoto" className="nextBtn">
                      Back
                    </Link>
                    <div className="foo-btns">
                      <button type="submit" className="nextBtn">
                        Save
                      </button>
                      <Link to="/Amenities" className="nextBtn">
                        Next
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </section>
        </div>
        <div className="error-success">
          {successMsg && (
            <div className="sucessMsg">
              <div className="msgCon">
                <i className="fa fa-check" aria-hidden="true"></i> {successMsg}
              </div>
              <div className="msgClose" onClick={this.handleSuccClose}>
                <i className="fa fa-times" aria-hidden="true"></i>
              </div>
            </div>
          )}

          {errorMsg && (
            <div className="errorMsg">
              <div className="msgCon">
                <i className="fa fa-times" aria-hidden="true"></i> Error:{" "}
                {errorMsg}
              </div>
              <div className="msgClose" onClick={this.handleErrorClose}>
                <i className="fa fa-times" aria-hidden="true"></i>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Salonphoto;
