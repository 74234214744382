import React, { Component } from "react";
import { Link, Navigate } from "react-router-dom";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";
import Header from "../Header/index";

class Services extends Component {
  constructor(props) {
    super(props);
    const retrievedObject = getLocalStorageItem("onboardingDetails");

    this.state = {
      userId: retrievedObject ? retrievedObject.userId : "",
      token: retrievedObject ? retrievedObject.token : "",
      successMsg: "",
      errorMsg: "",
      redirect: false,
      errors: null,
      service: "",
    };
  }

  componentDidMount() {
    this.fetchUserData();
  }

  fetchUserData = async () => {
    const { token, userId } = this.state;

    const { AdminExpertUserDetails } = adminendPoints;
    const method = "POST";
    const contentType = "application/json";

    try {
      const body = { token, userid: userId };
      const response = await APICaller(
        AdminExpertUserDetails,
        method,
        body,
        contentType,
        token
      );

      const { status, user } = response.data;

      if (status === 200 && user) {
        this.setState({
          service: user?.srviceForMenWomen || "",
        });
      } else {
        console.error("Failed to fetch user data");
        this.setState({ errorMsg: "Failed to fetch user data" });
      }
    } catch (error) {
      console.error("Failed to fetch user data:", error);
      this.setState({ errorMsg: "Failed to fetch user data" });
    }
  };

  validateForm = () => {
    const errors = {};
    const { service } = this.state;

    if (!service) {
      errors.service = "Service selection is required.";
    }

    return errors;
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = this.validateForm();

    if (Object.keys(newErrors).length > 0) {
      this.setState({ errorMsg: "", successMsg: "", errors: newErrors }, () => {
        setTimeout(() => {
          this.setState({ errors: null });
        }, 5000);
      });
    } else {
      this.setState({ errors: null });
      const { userId, service, token } = this.state;

      const { OnboardingUpdateExpertServices } = adminendPoints;
      const method = "POST";
      const body = { userId, srviceForMenWomen: service, token };
      const contentType = "application/json";

      try {
        const response = await APICaller(
          OnboardingUpdateExpertServices,
          method,
          body,
          contentType
        );

        const { status, data } = response;

        if (data && status === 200) {
          this.setState(
            {
              successMsg: "Service updated successfully.",
              errorMsg: "",
            },
            () => {
              setTimeout(() => {
                this.setState({ successMsg: "" });
                this.setState({ redirect: true });
              }, 5000);
            }
          );
        } else {
          this.setState(
            {
              errorMsg: "Invalid service selection.",
              successMsg: "",
            },
            () => {
              setTimeout(() => {
                this.setState({ errorMsg: "", redirect: false });
              }, 3000);
            }
          );
        }
      } catch (error) {
        console.log("Error making data call:", error);
        this.setState({
          errorMsg: "An error occurred. Please try again.",
          successMsg: "",
        });
      }
    }
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    const { errors, redirect, successMsg, errorMsg, service, token } =
      this.state;

    if (!token) {
      return <Navigate to="/" />;
    }

    if (redirect) {
      return <Navigate to="/treatment" />;
    }

    return (
      <div>
        <Header />
        <section className="common-content">
          <div className="container">
            <div className="form-area custom-width-750 customForm">
              <div className="form-body">
                <h3>Which of these best describes your services?</h3>
                <form className="custom-form" onSubmit={this.handleSubmit}>
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                      <div className="mb-3">
                        <div className="services">
                          {[
                            { label: "For Men", value: "Men" },
                            { label: "For Women", value: "Women" },
                            { label: "Both", value: "Both" },
                          ].map(({ label, value }) => (
                            <label
                              key={value}
                              className={`service ${
                                service === value ? "active-check" : ""
                              }`}
                            >
                              <i className="fa fa-check" aria-hidden="true"></i>
                              <input
                                className="child-checkbox"
                                type="radio"
                                name="service"
                                value={value}
                                onChange={this.handleInputChange}
                              />
                              <span>
                                <div className="servicecol">
                                  <h6>{label}</h6>
                                  <p>
                                    Lorem Ipsum is simply dummy text of the.
                                  </p>
                                </div>
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/img/service.png"
                                  }
                                  alt="service"
                                />
                              </span>
                            </label>
                          ))}
                        </div>
                        {errors && errors.service && (
                          <div className="alert alert-danger">
                            {errors.service}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="footer-container">
                    <div className="progress">
                      <div
                        className="progress-bar w-30"
                        role="progressbar"
                        aria-valuenow="75"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                    <div className="container">
                      <div className="next-prv-button">
                        <button type="button" className="prevBtn">
                          Back
                        </button>
                        <div className="nextBtn-wrap">
                          <button type="button" className="prevBtn">
                            Save & exit
                          </button>
                          <button type="submit" className="nextBtn">
                            Next
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {successMsg && (
                    <div className="alert alert-success">{successMsg}</div>
                  )}
                  {errorMsg && (
                    <div className="alert alert-danger">{errorMsg}</div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Services;
