import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { storeAdminDetail } from "../../store/actions/admin";
import { useNavigate } from "react-router";
import { removeLocalStorageItem } from "../../store/localStorage";

function Logout({ onboardingDetails, dispatch }) {
  const [redirect, setRedirect] = useState(false);
  const [errors, setErrors] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    getQRCode();
  }, []);

  const getQRCode = () => {
    dispatch(storeAdminDetail({}));
    removeLocalStorageItem("onboardingDetails");
    setErrors("You have been successfully logged out.");
    setRedirect(true);
  };

  useEffect(() => {
    if (redirect) {
      navigate("/");
      // window.location.reload(false);
    }
  }, [redirect, navigate]);

  return (
    <div className="login-box">
      <div className="card card-outline card-primary">
        <div className="card-header text-center">
          <Link to="/" className="h1">
            <b>Admin</b>LTE
          </Link>
        </div>
      </div>
      {errors && <div>{errors}</div>} {/* Display the logout message */}
    </div>
  );
}

const mapStateToProps = (state) => ({
  onboardingDetails: state.admin.onboardingDetails,
});

export default connect(mapStateToProps)(Logout);
