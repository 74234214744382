import React, { Component } from "react";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { getLocalStorageItem } from "../../store/localStorage";
import Header from "../Header/index";

class UploadPhoto extends Component {
  constructor(props) {
    super(props);

    const retrievedObject = getLocalStorageItem("onboardingDetails");
    this.state = {
      userId: retrievedObject ? retrievedObject.userId : "",
      token: retrievedObject ? retrievedObject.token : "",
      successMsg: "",
      errorMsg: "",
      redirect: false,
      errors: null,
      image: null,
      imagePreviewUrl: "assets/img/Union.png",
      loading: false,
      userProImage: [],
    };
  }

  componentDidMount() {
    this.fetchUserData();
  }

  fetchUserData = async () => {
    this.setState({ loading: true });
    const { token, userId } = this.state;
    const { AdminExpertUserDetails } = adminendPoints;
    const method = "POST";
    const contentType = "application/json";

    try {
      const body = { token, userid: userId };
      const response = await APICaller(
        AdminExpertUserDetails,
        method,
        body,
        contentType,
        token
      );

      const { status, user } = response.data;

      if (status === 200 && user) {
        let userProfileImage = "assets/img/Union.png"; // Default image

        if (
          user?.user_profile_images?.length > 0 &&
          user.user_profile_images[0].is_featured === "1"
        ) {
          userProfileImage = `https://mystylist-media.s3.amazonaws.com/${user.user_profile_images[0].image}`;
        }

        this.setState({
          imagePreviewUrl: userProfileImage,
          loading: false,
          userProImage: user.user_profile_images || [],
        });
      } else {
        console.error("Failed to fetch user data");
        this.setState({
          errorMsg: "Failed to fetch user data",
          loading: false,
        });
      }
    } catch (error) {
      console.error("Failed to fetch user data:", error);
      this.setState({ errorMsg: "Failed to fetch user data", loading: false });
    }
  };

  handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        this.setState({ image: file, imagePreviewUrl: reader.result });
      };
      reader.readAsDataURL(file);
    }
  };

  handleRemoveImage = async () => {
    this.setState({
      image: null,
      imagePreviewUrl: "assets/img/Union.png",
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    this.setState({ loading: true });
    const { image, userId } = this.state;

    if (!image) {
      this.setState({
        redirect: true,
      });
    } else {
      const { AdminUploadExpertGalleryNew } = adminendPoints;
      const formData = new FormData();
      formData.append("user_profile_images", image);
      formData.append("id", userId);

      try {
        const response = await APICaller(
          AdminUploadExpertGalleryNew,
          "POST",
          formData,
          {
            "Content-Type": "multipart/form-data",
          }
        );

        const { status, data } = response;

        if (data && status === 200) {
          this.setState(
            {
              successMsg: "User Profile Image updated successfully.",
              errorMsg: "",
              loading: false,
            },
            () => {
              setTimeout(() => {
                this.setState({ successMsg: "", redirect: true });
              }, 5000);
            }
          );
        } else {
          this.setState(
            {
              errorMsg: "Failed to update data. Please try again.",
              successMsg: "",
              loading: false,
            },
            () => {
              setTimeout(() => {
                this.setState({ errorMsg: "", redirect: false });
              }, 3000);
            }
          );
        }
      } catch (error) {
        console.log("Error uploading image:", error);
        this.setState({
          errorMsg: "An error occurred. Please try again.",
          successMsg: "",
          loading: false,
        });
      }
    }
  };

  handleNext = () => {
    this.setState({ redirect: true });
  };

  handleSuccClose = (e) => {
    this.setState({
      successMsg: "",
    });
  };

  handleErrorClose = (e) => {
    this.setState({
      errorMsg: "",
    });
  };

  render() {
    const {
      successMsg,
      errorMsg,
      redirect,
      imagePreviewUrl,
      loading,
      image,
      token,
    } = this.state;

    if (!token) {
      return <Navigate to="/" />;
    }

    if (redirect) {
      return <Navigate to="/Salonphoto" />;
    }

    return (
      <div>
        <Header />
        <div className="content-area">
          <section className="common-content">
            <form onSubmit={this.handleSubmit}>
              <div className="container">
                <div className="form-area custom-width customForm">
                  <div className="form-body location-form">
                    <div className="section-title">
                      <h3>Add a Profile Photo</h3>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="mb-3">
                          <div className="upload-container">
                            <div className="upload-placeholder sprofile">
                              <div className="image-container">
                                <img
                                  src={imagePreviewUrl}
                                  alt="Selected"
                                  id="imagePreview"
                                />
                                {image && (
                                  <i
                                    className="fa-solid fa-trash-can"
                                    onClick={() => this.handleRemoveImage()}
                                  ></i>
                                )}
                              </div>
                              <input
                                type="file"
                                id="fileInput"
                                accept="image/*"
                                onChange={this.handleImageChange}
                                ref={(input) => (this.fileInput = input)}
                                hidden
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {loading && <div className="loader"></div>}
                  </div>
                </div>
              </div>
              <div className="footer-container">
                <div className="container">
                  <div className="next-prv-button">
                    <Link to="/Profile" className="prevBtn">
                      Back
                    </Link>
                    <div className="foo-btns">
                      {!image ? (
                        <button
                          type="button"
                          id="customButton"
                          className="cusBtn"
                          onClick={() => this.fileInput.click()}
                        >
                          <img
                            src="assets/img/upload-icon.png"
                            alt="Upload Icon"
                            className="icon"
                          />
                          Upload Image
                        </button>
                      ) : (
                        <div>
                          <button
                            type="submit"
                            id="doneButton"
                            className="cusBtn"
                          >
                            Submit
                          </button>
                        </div>
                      )}

                      <Link to="/Salonphoto" className="nextBtn">
                        Next
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </section>
        </div>
        <div className="error-success">
          {successMsg && (
            <div className="sucessMsg">
              <div className="msgCon">
                <i className="fa fa-check" aria-hidden="true"></i> {successMsg}
              </div>
              <div className="msgClose" onClick={this.handleSuccClose}>
                <i className="fa fa-times" aria-hidden="true"></i>
              </div>
            </div>
          )}

          {errorMsg && (
            <div className="errorMsg">
              <div className="msgCon">
                <i className="fa fa-times" aria-hidden="true"></i> Error:{" "}
                {errorMsg}
              </div>
              <div className="msgClose" onClick={this.handleErrorClose}>
                <i className="fa fa-times" aria-hidden="true"></i>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  adminDetails: state.admin.adminDetails,
});

export default connect(mapStateToProps)(UploadPhoto);
