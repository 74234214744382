import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import { getLocalStorageItem } from "../../store/localStorage";

export default class Header extends Component {
  constructor(props) {
    super(props);
    const retrievedObject = getLocalStorageItem("onboardingDetails");
    this.state = {
      notificationVisible: false,
      userId: retrievedObject ? retrievedObject.userId : "",
      token: retrievedObject ? retrievedObject.token : "",
    };
  }

  handleLinkClick = () => {
    this.setState((prevState) => ({
      notificationVisible: !prevState.notificationVisible,
    }));
  };

  renderProgressBar() {
    const progressMap = {
      "/Location": "",
      "/Profile": 16.66,
      "/UploadPhoto": 33.32,
      "/Salonphoto": 49.98,
      "/Amenities": 66.68,
      "/Treatment": 83.35,
      "/ThankYou": 100,
    };

    const currentPath = window.location.pathname;
    const progressPercentage = progressMap[currentPath];

    if (progressPercentage !== undefined) {
      return (
        <div className="progress">
          <div
            className="progress-bar"
            role="progressbar"
            style={{ width: `${progressPercentage}%` }}
            aria-valuenow={progressPercentage}
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
      );
    }
    return null; // Return null if there's no matching path
  }

  render() {
    const { token, userId } = this.state;
    return (
      <header className="main-header">
        <nav className="custom-navbar navbar navbar-expand-lg navbar-light bg-light">
          <div className="container">
            <a href="#">
              <img
                className="siteLogo lazyload"
                src={process.env.PUBLIC_URL + "/assets/img/logo.png"}
                alt="Logo"
                title=""
              />
            </a>

            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarNavDropdown">
              <ul className="navbar-nav ml-auto">
                {userId && (
                  <li className="nav-item dropdown">
                    <NavLink
                      className="account-icon nav-link dropdown-toggle"
                      to="#"
                      id="userDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL + "/assets/img/accout-icon.png"
                        }
                        alt="User Icon"
                      />
                    </NavLink>
                    <div
                      className="dropdown-menu dropdown-menu-right"
                      aria-labelledby="userDropdown"
                    >
                      <div className="dropdown-divider"></div>
                      <NavLink className="dropdown-item" to="/Logout">
                        Logout
                      </NavLink>
                    </div>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </nav>
        {this.renderProgressBar()} {/* Render the progress bar conditionally */}
      </header>
    );
  }
}
