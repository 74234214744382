import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { adminendPoints } from "../../config";
import APICaller from "../../utils/apicaller";
import { setLocalStorageItem } from "../../store/localStorage";
import Header from "../Header/index";

class PrivacyPolicy extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <Header />
        <section className="common-content">
          <div className="container">
            <div>
              <h1>Privacy Policy of My Stylist</h1>
              <p>
                My Stylist, a creation of RoSe Technologies ("My Stylist", "we",
                "us", or "our"), places the utmost importance on the privacy and
                security of its users ("User", "you", or "your"). This
                commitment is at the core of our operations and is reflected in
                this Privacy Policy. Here, we detail our stringent practices for
                the collection, use, safeguarding, and disclosure of information
                provided by you or gathered by us during your use of our mobile
                application (the "App"), specifically within the geographical
                confines of Chandigarh, Mohali, and Delhi, India. Our approach
                is designed to ensure compliance with the highest standards of
                privacy protection, reflecting our dedication to respecting and
                securing your personal information.
              </p>
              <h2>1. What information do we collect?</h2>
              <p>
                1.1. Our commitment to enhancing service quality and delivering
                a bespoke experience compels us to gather a broad spectrum of
                information from both our users and stylists:
              </p>
              <p>
                1.2. <strong>Information You Provide to Us:</strong>
              </p>
              <p>
                1.2.1. We meticulously collect key personal information, such as
                your full name, email address, mobile number, and other contact
                details, to foster a personalised user experience. For our
                younger users, those under the age of 18, we employ robust
                parental control mechanisms and mandate explicit parental or
                guardian consent, ensuring a secure and respectful platform for
                all.
              </p>
              <p>
                1.2.2. To assure the authenticity of our stylists and facilitate
                seamless interactions between users and stylists, we collect
                detailed information from stylists. This includes, but is not
                limited to, full name, Aadhar number (as a unique identifier for
                verification purposes), precise GPS location to verify the
                stylist's operational area, and the physical shop address for
                user convenience and trust. Such comprehensive data collection
                is pivotal in maintaining the integrity and reliability of our
                service.
              </p>
              <p>
                1.2.3. In our quest to tailor our services and recommendations
                to your unique tastes and needs, we delve into collecting
                specific details about your style preferences, service
                expectations, and interests. This includes your preferred
                styles, favourite products, appointment preferences (such as
                preferred times and locations), and any specific needs or
                requests you might have. Gathering this nuanced information
                allows us to refine our offerings and ensure a truly
                personalised and satisfying experience, catering directly to
                your individual preferences and lifestyle.
              </p>
              <p>
                1.2.4. Upon receiving your explicit consent, we collect precise
                GPS location data from your device. This information is crucial
                for tailoring our services and suggestions to your current
                locale, specifically within the cities of Chandigarh, Mohali,
                and Delhi, India. By understanding your geographical context, we
                can offer you more relevant stylist options, appointment
                availability, and personalised recommendations, ensuring that
                our services are as convenient and tailored to your needs as
                possible.
              </p>
              <p>
                1.2.5. We meticulously monitor and analyse your interactions
                within our app to continuously enhance and customise your user
                experience. This encompasses a wide array of data, including
                your service selections, submitted feedback, saved favourites,
                and browsing history within the app. By understanding your
                preferences and behaviours, we can refine our algorithms to
                better match you with stylists and services that align with your
                tastes, improve our platform's usability, and introduce new
                features and services that resonate with your desires and
                expectations. This proactive approach allows us to create a more
                engaging and satisfying experience tailored to your individual
                preferences and needs.
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    adminDetails: state.admin.adminDetails,
    phone:
      state.admin.adminDetails && state.admin.adminDetails.data
        ? state.admin.adminDetails.data.phone
        : "",
  };
};

export default connect(mapStateToProps)(PrivacyPolicy);
